import React from 'react'
//import {Link} from 'react-router-dom';
//import card2 from '../img/Airtime.svg'


function Contactcard(props) {

  return (
    <div className='Aboutcard' onClick={props.toggleall}>



    <div className='about-container bg'>
        <div className='about-text'>
            <h2>Get In Touch With Us</h2>
            {/* <div className='card-img'>
            <img src={card2} alt="Airtime and Data Topup" />
            </div> */}

            <span>To get in touch with us, please send an email to: </span>
            <span>support@tribewallet.co </span>
            
        </div>
        
    </div>


    </div>
  )
}

export default Contactcard