import React from 'react'
//import {Link} from 'react-router-dom';
//import card2 from '../img/Airtime.svg'
import Lottie from "lottie-react";
import Business from "../img/Business-team.json";


const style = {
  height: 700,
};

function Aboutcard(props) {

  return (
    <div className='Aboutcard' onClick={props.toggleall}>



    <div className='about-container bg'>
        <div className='about-text'>
            <h2>About Us</h2>
            {/* <div className='card-img'>
            <img src={card2} alt="Airtime and Data Topup" />
            </div> */}
            <Lottie className='card-img'
            animationData={Business}
            loop={true}
            style={style}
             />

          <span>Tribe Wallet is a simplified digital mobile wallet.
          You can do more with just your mobile phone using Tribe.
          Access your bank accounts and financial services, create physical and virtual cards and pay anywhere in the world, pay utility and internet bills, save and earn 20% annual interest, get amazing investment offers, trade Giftcards, pay educational fees, top-up your sports wallet, convert your Airtime to cash and even more. All in One App.
          </span>
            
            
        </div>
        
    </div>


    </div>
  )
}

export default Aboutcard