import React from 'react'
import providers from '../img/Providers.png'
function Newsletter(props) {


  return (
    
    <div onClick={props.toggleall}>
    <div className='news'>
    <h1>We have integrated over 20+ service providers<br></br> and brands to Tribe</h1>
    <p>Our users experience matters so much to us. We have simplified mobile wallet to make transactions and purchases easier just for you.</p>
    <h2 className='happyusers'>Quality Services + User Experience = Happy Customers</h2>
    </div>
     <img className='provider' src={providers} alt="Providers"/>
     </div>

  )
}

export default Newsletter