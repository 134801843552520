import React from 'react';
import { useState } from 'react';
import {BiMenuAltRight} from 'react-icons/bi';
import {AiOutlineClose} from 'react-icons/ai';
import {Link} from 'react-router-dom';
import {motion, AnimatePresence} from 'framer-motion'
import arrow from '../img/down-arrow.svg'
import  logo from '../img/logo-header.svg'


function Nav(props) {
const [menu, setMenu] = useState(false);
const [navbar, setNavbar] = useState(false);



const changeBackground = ()=>{
  if(window.scrollY > 70){
    setNavbar(true);
  }else{
    setNavbar(false);
  }

}
window.addEventListener('scroll', changeBackground)

const menuToggle = () =>{
    setMenu((p) => !p)
}

// animations
let easing = [0.6, -0.05, 0.01, 0.99];
const stagger ={
  animate:{
    transition:{
      delayChildren:0.4,
      staggerChildren:0.2,
      staggerDirection:1
    }
  }
}

const header={
  initial:{
    y:-60,
    opacity:0,
    transition:{duration:0.8, ease:easing}
  },
  animate:{
    y:0,
    opacity:1,
    animation:{
      duration:0.6,
      ease:easing
    }
  }
}

const item={
  exit:{
    opacity:0,
    height:0,
    transition:{
      ease:"easeInOut",
      duration:0.3,
      delay:1.2
    }
  }
}

  return (
    <motion.div 
    initial= 'initial' 
    className={navbar ? "Nav active" :'Nav'}
    style={{height: !menu ? '4em':'60vh'}}
    animate='animate'
    >
    <AnimatePresence>
        { setMenu && (
          <motion.div variants={stagger} className='navContainer'>
          <div className='logoham' 
    onClick={props.toggleall}>
            <motion.h2 variants={header}>
                  <Link id="logo" to="/" className='navlink'><img src={logo} alt="logo" /></Link> 
            </motion.h2>
            <motion.div 
              variants={item}
              initial={{height:0,opacity:0}}
              animate={{height:"100vh", opacity:1}}
              transition={{duration:.6}}
              exit="exit"
              className='toggle'
         >
         {!menu?  (
          <BiMenuAltRight onClick={menuToggle}/>) : (<AiOutlineClose  onClick={menuToggle} />) }
         </motion.div>
         </div>
            <motion.nav 
            //  variants={header}  
            className={!menu ? "" :'menuOpen'}>
            <motion.ul>
                <motion.li 
                  initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.6}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:6
                        }
                    }}><Link  className='navlink' to="/">Home</Link> </motion.li>

                

                <motion.li
                className='li'
                 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.6}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.6
                        }
                    }}><Link  className='navlink' to="" onClick={props.togglesubmenu}>Products
                    <img src={arrow} alt="arrow" className='arrow'/>
                    </Link>
                    
                   {props.submenu && (
                    <motion.ul className='sub-menu'>
                    <div className='sub-container'>
                    <motion.h3 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }
                    }}>Our Products</motion.h3>
                    <div className='grid'>
                      <motion.li initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }
                    }}><Link  className='navlinksub' to="/banking">Mobile Banking</Link></motion.li>
                      <motion.li initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }
                    }}><Link  className='navlinksub' to="/airtime-data">Airtime and Data</Link></motion.li>
                      <motion.li initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }

                        // Added New Links

                      }}><Link  className='navlinksub' to="/utility">Utility Recharge</Link></motion.li>
                      <motion.li initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }

                        
                      }}><Link  className='navlinksub' to="/airtime-cash">Airtime To Cash</Link></motion.li>
                      <motion.li initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }



                      }}><Link  className='navlinksub' to="/giftcard">Giftcards Trading</Link></motion.li>
                      <motion.li initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }



                      }}><Link  className='navlinksub' to="/spin">Spin and Win</Link></motion.li>
                      <motion.li initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }


                      }}><Link  className='navlinksub' to="/education-sports">Education and Sports</Link></motion.li>
                      <motion.li initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }

                        // End Added New Links
                        
                    }}><Link  className='navlinksub' to="/physical-vitual">Physical and Vitual Cards</Link></motion.li>
                      <motion.li initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }
                    }}><Link  className='navlinksub' to="/savings">Savings and Investment</Link></motion.li>
                    </div>
                    </div>
                    </motion.ul>)} 
                   </motion.li>

                    
                <motion.li
                className='li'
                 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.6}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.6
                        }
                    }}><Link  className='navlink' to=""  onClick={props.togglesubmenu1}>Company
                    <img src={arrow} className="arrow" alt="arrow"/>
                    </Link>
                     
                     {props.submenu1 && (
                      <motion.ul className='sub-menu'>
                    <motion.h3 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }
                    }}>Legal</motion.h3>
                      <motion.li className='li'
                 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }
                    }}><Link  className='navlinksub' to="/about" >About</Link></motion.li>

                 <motion.li className='li'
                 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }
                    }}><Link  className='navlinksub' to="/terms" >Terms and Conditions</Link></motion.li>


                <motion.li className='li'
                 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }
                    }}><Link  className='navlinksub' to="/privacy" >Privacy Policy</Link></motion.li>


                <motion.li className='li'
                 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }
                    }}><Link  className='navlinksub' to="/faq" >FAQ</Link></motion.li>


                      <motion.li className='li'
                 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.3}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.3
                        }
                    }}> <Link  className='navlinksub' to="/contact">Contact</Link></motion.li>
                    </motion.ul>

                     )} 

                   </motion.li>

                   <motion.li
                className='li'
                 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.6}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.6
                        }
                    }}><Link  className='navlink' to="/career">Careers</Link> </motion.li>


                <motion.li
                className='li'
                 initial={{y:80,opacity:0}}
                    animate={{y:0, opacity:1}}
                    transition={{delay:.6}}
                    exit={{
                      opacity:0,
                      y:90,
                        transition:{
                          ease:"easeInOut",
                          delay:.6
                        }
                    }}><Link  className='navlink' to="/team">Our Team</Link> </motion.li>
                
                </motion.ul>
            
            </motion.nav>


         
        </motion.div>
        )}
         
        </AnimatePresence>
    </motion.div>
    
  )
}

export default Nav;