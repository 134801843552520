import React from 'react'
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion'
import play from '../img/playstore.svg'
import apple from '../img/apple.svg'
import  twitter from '../img/twitter.svg'
import  youtube from '../img/youtube.svg'
import  instagram from '../img/instagram.svg'
import  facebook from '../img/facebook.svg'
import  logo from '../img/logo-footer.svg'

function Footer(props) {
  return (
    <div className='footer-container' onClick={props.toggleall}>
      <div className="footer">
      <div className="footerleft">
        <h2><Link id="logo" to="/" className='footerlogo'><img src={logo} alt="logo" /></Link> </h2>
        <p className='footerinfotext'>Tribe Wallet is a simplified digital mobile wallet.
You can do more with just your mobile phone using Tribe.
Access your bank accounts and financial services, create physical and virtual cards and pay anywhere in the world, pay utility and internet bills, save and earn 20% annual interest, get amazing investment offers, trade Giftcards, pay educational fees, top-up your sports wallet, convert your Airtime to cash and even more. All in One App.
 </p>
        <motion.div className="footerbtn">
          <motion.button    variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}>
        <a href="https://www.tribewallet.co">
        <img src={play} alt="Playstore"></img>
        </a>
            
          </motion.button>
          <motion.button    variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}>
        <a href="https://www.tribewallet.co">
        <img src={apple} alt="Applestore"></img>
        </a>
          </motion.button>
        </motion.div>
      </div>

      <div className="footerright">
            <div className="company">
              <p className="linkTitle">Company</p>
              <Link  className='footerlink' to="/about">About Us</Link> 
              <Link  className='footerlink' to="/career">Careers</Link>
              <Link  className='footerlink' to="/team">Our Team</Link> 
              <a href="https://blog.tribewallet.co" className='footerlink'>Blog</a> 
              <Link  className='footerlink' to="/privacy">Privacy Policy</Link> 
              <Link  className='footerlink' to="/terms">Terms Of Services</Link> 
            </div>
            <div className="support">
              <p className="linkTitle">Support</p>
              <Link  className='footerlink' to="/contact">Contact Us</Link>
              <Link  className='footerlink' to="/faq">FAQ</Link> 
              <Link  className='footerlink' to="/usersecurity">Security</Link>
            </div>
            <div className="products">
              <p className="linkTitle">Products</p>
              <Link  className='footerlink' to="/banking">Mobile Banking</Link> 
              <Link  className='footerlink' to="/airtime-data">Airtime and Data Topup</Link> 
              <Link  className='footerlink' to="/utility">Utility Recharge</Link>
              <Link  className='footerlink' to="/airtime-cash">Airtime To Cash</Link>
              <Link  className='footerlink' to="/giftcard">Giftcards Trading</Link>
              <Link  className='footerlink' to="/savings">Savings and Investment</Link>
              <Link  className='footerlink' to="/education-sports">Education and Sports</Link>
              <Link  className='footerlink' to="/physical-vitual">Physical and Vitual Cards</Link>
              <Link  className='footerlink' to="/spin">Spin and Win</Link>
            </div>
            <div className= "contact">
              <p className="linkTitle">Contact</p>
              <a href="mailto:support@tribewallet.co" className='footerlink'>support@tribewallet.co</a> 
             
              
              <motion.div className="footerbtn">
              <motion.button    variants={props.btnGroup}
              whileHover={{scale:1.05}}
              whileTap={{scale:0.95}}>
              <a href="https://facebook.com/mytribewallet" target="_blank" rel="noreferrer">
              <img src={facebook} alt="Facebook"></img>
              </a>
              </motion.button>
              <motion.button    variants={props.btnGroup}
              whileHover={{scale:1.05}}
              whileTap={{scale:0.95}}>
              <a href="https://twitter.com/mytribewallet" target="_blank" rel="noreferrer">
              <img src={twitter} alt="Twitter"></img>
              </a>
              </motion.button>
              <motion.button    variants={props.btnGroup}
              whileHover={{scale:1.05}}
              whileTap={{scale:0.95}}>
              <a href="https://instagram.com/mytribewallet" target="_blank" rel="noreferrer">
              <img src={instagram} alt="Instagram"></img>
              </a>
              </motion.button>
              <motion.button    variants={props.btnGroup}
              whileHover={{scale:1.05}}
              whileTap={{scale:0.95}}>
              <a href="https://youtube.com/@mytribewallet" target="_blank" rel="noreferrer">
              <img src={youtube} alt="Youtube"></img>
              </a>
              </motion.button>
              </motion.div>
               {/*icons  */}
            </div>
      </div>
      </div>
  <p className='copy'>Copyright &copy; Tribe Technologies 2024 - All rights reserved.</p>
  
    </div>
  )
}

export default Footer;