import React from 'react'
//import {Link} from 'react-router-dom';
//import card2 from '../img/Airtime.svg'


function Privacycard(props) {

  return (
    <div className='Aboutcard' onClick={props.toggleall}>



    <div className='about-container bg'>
        <div className='about-text'>
            <h2>Our Privacy And Policy</h2>
            <br></br>
            <h4>Updated 12/06/2023</h4>
            {/* { <div className='card-img'>
            <img src={card2} alt="Airtime and Data Topup" />
            </div> } */}

          <br></br>
          <br></br>

<h4>Overview:</h4>
<p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Tribapay unless otherwise defined in this Privacy Policy.</p>

<h4>Information Collection and Use:</h4>

<p>1. You hereby declare that you are a human being over 18 years of age and of a sound mind, or at least the age of majority in the jurisdiction where you reside and from which you use the Services.</p>

<p>2. You agree and consent to the blocking and/or restriction of your Tribe Account with or without notice to you if we believe, in our sole discretion, that you are less than 18 years of age. You also agree that we reserve the right to block and/or restrict your Tribe Account on suspicion of illegal/fraudulent activities, other activities that directly or indirectly breach the provisions of these Terms, breaches of applicable laws, or as a result of us obeying a court or regulator’s order.</p>

<p>3. You agree that we will use the phone number and email you provided to us when you created your Tribe Account or as updated by you from time to time as the primary mode of communicating with you.</p>

<p>4. As part of our security procedures, you determine your login details. Keep your login details secret; do not disclose them to anyone. We do not know your login details. If you forget your login details or know or suspect that a third party has gained access to your Tribe Account, you must promptly change your login details by resetting your password or by contacting us to place a restriction on your Tribe Account.</p>

<p>5. You confirm that all information you provide to us is true, correct,and not misleading. You agree to provide any additional documents and/or information that may be required from time to time.</p>

<p>6. You consent to us collecting and using technical information about the App and related software, hardware and peripherals to improve the App and provide the Services to you. If you use the Services, you consent to us and our affiliates' and licensees' processing, transmission, collection, retention, maintenance, and use of your personal data to improve Our Services and/or your experience while using the App.</p>

<p>7. By accessing this App, you hereby authorise us to provide the Services herein contemplated using the App.</p>

<p>2. Personal Data</p>
<p>By opening a Tribe Account, you consent to us processing your personal data for the purpose of providing the Services and for the purposes described in our Privacy Policy.</p>

<h4>Log Data:</h4>
<p>We want to inform you that whenever you use our Service, in a case of an error in the platform we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the platform when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
<h4>Cookies:</h4>
<p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.

This Service does not use these “cookies” explicitly. However, the platform may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>

<h4>Service Providers:</h4>
<p>We may employ third-party companies and individuals due to the following reasons:</p>

<p><li>To facilitate our Service</li>
<li>To provide the Service on our behalf</li>
<li>To perform Service-related services or</li>
<li>To assist us in analyzing how our Service is used.</li> </p>
<p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

<h4>Ads On Tribe</h4>
<p>We partner with third party ad servers, ad networks and social media platforms (like Facebook and Google) to deliver personalized advertisements (“ads”) on our Services and other sites that may be of interest to you and/or to measure their effectiveness. </p>

<p>Tribe may share certain information with our third party advertising partners, such as your email address, location, cookie information and information relating to your use of our Services, and allow partners to perform a match of your information against information from other third party networks or sites to serve ads either on the Service or on third party sites (including, but not limited to Facebook, Google and Samsung televisions) and to measure the effectiveness of these ads. </p>

<p>In addition, these third-party ad servers or ad networks may use technology to send, directly to your browser or mobile device, these personalized ads and ad links, and will automatically receive your IP address when they do so. They may also use other technologies (such as cookies, JavaScript, device identifiers, location data, and clear gifs, see above) to compile information about your browser's or device's visits and usage patterns on the Service, and to measure the effectiveness of their ads and to personalize the advertising content.</p>

<p>Tribe does not sell, rent, or share the information we collect directly from you or about you from third parties with these third-party ad servers or ad networks for such parties' own marketing purposes.</p>

<p>Please note that an advertiser may ask Tribe to show an ad to a certain audience of Users (e.g., based on demographics or other interests). In that situation, Tribe determines the target audience and Tribe serves the advertising to that audience and only provides anonymous aggregated data to the advertiser. If you respond to such an ad, the advertiser or ad server may conclude that you fit the description of the audience they are trying to reach.</p>

<p>The Tribe Privacy Policy does not apply to, and we cannot control the activities of, third-party advertisers. Please consult the respective privacy policies of such advertisers or contact such advertisers for more information.</p>

<h4>Security:</h4>
<p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

<h4>Links to Other Sites:</h4>
<p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

<h4>Children's Privacy:</h4>
<p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>

<h4>Changes to This Privacy Policy:</h4>
<p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. This policy is effective as of June 12th, 2023.</p>

<h4>Contact Us:</h4>
<p>If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at support@tribewallet.co.</p>
        </div>
        
    </div>


    </div>
  )
}

export default Privacycard