import React from 'react'
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion'
import card1 from '../img/Bank.svg'
import card2 from '../img/Airtime.svg'
import card3 from '../img/Utility.svg'
import card4 from '../img/Giftcard.svg'
import card5 from '../img/Card.svg'
import card6 from '../img/Savings.svg'
import card7 from '../img/Convert.svg'
import card8 from '../img/Education.svg'
import card9 from '../img/Spin.svg'


function Card(props) {

  return (
    <div className='Card' onClick={props.toggleall}>

    <div className='card-container one'>
        <div className='card-text'>
            <h2>Mobile Banking</h2>
Experience simplified mobile banking.
You can track and manage your finances all in one place.
With Tribe Wallet, you can send and receive money to and from anyone across the country at superfast speed.
We have unlocked a new world of banking possibilities, now you can connect your bank accounts and start managing them from a single App and do even more with our mobile App.
            
            <Link to="/banking"> 
            <motion.button    
            variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}
         className="card-btn">Learn more
          </motion.button>
          </Link>
        </div>
        <div className='card-img'>
            <img src={card1} alt="Mobile Banking" />
        </div>
    </div>

    <div className='card-container two'>
        <div className='card-text'>
            <h2>Airtime and Data Topup</h2>
            Stay connected with your family and friends. Don't run out airtime and data.
            With Tribe Wallet you can easily topup your airtime and data at highly discounted price, on all networks, and get some reward points. 
            <Link to="/airtime-data"> 
            <motion.button    
            variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}
         className="card-btn">Learn more
          </motion.button>
          </Link>
        </div>
        <div className='card-img'>
            <img src={card2} alt="Airtime and Data Topup" />
        </div>
    </div>

    <div className='card-container three'>
        <div className='card-text'>
            <h2>Utility Recharge</h2>
Don't get disconnected.
Would you like to pay for your Cable subscription, Electricity bills, Telecomms, Waste bills, Water rates and Gas?
With Tribe Wallet, you can do these within few seconds.
You worked for your money, paying utility bills should not be difficult.
We have made these process easier and faster for you using our mobile App.
 
            <Link to="/utility"> 
            <motion.button    
            variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}
         className="card-btn">Learn more
          </motion.button>
          </Link>
        </div>
        <div className='card-img'>
            <img src={card3} alt="Utility Recharge" />
        </div>
    </div>

    <div className='card-container four'>
        <div className='card-text'>
            <h2>Airtime To Cash</h2>
Did you mistakenly over recharged your Airtime, or someone sent excess Airtime to you?
Don't beat yourself, we understand that feeling too.
Do not worry, you can easily convert your airtime to cash with Tribe Wallet, and your account will be credited almost instantly.

            <Link to="/airtime-cash"> 
            <motion.button
         variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}
         className="card-btn">Learn more
          </motion.button>
          </Link>
        </div>
        <div className='card-img'>
            <img src={card7} alt="Airtime To Cash" />
        </div>
    </div>

    <div className='card-container five'>
        <div className='card-text'>
            <h2>Gift Cards Trading</h2>
We have got the better, easiest and fastest way to buy, sell and trade 50+ Giftcards on Tribe Wallet.
You may have tried other platforms to buy and sell your Giftcards.
And you may have been over charged with the commission and conversion rate.
On Tribe Wallet, we put you first, and you don't have to pay higher commission for trading Giftcards.
You can buy and sell Giftcards from over 20 countries around the world effortlessly on Tribe Wallet.

            <Link to="/giftcard"> 
            <motion.button
            variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}
         className="card-btn">Learn more
          </motion.button>
          </Link>
        </div>
        <div className='card-img'>
            <img src={card4} alt="Giftcards Trading" />
        </div>
    </div>


    <div className='card-container six'>
        <div className='card-text'>
            <h2>Savings and Investment</h2>
Savings and Investment made easy.
You can choose your desired savings method with Tribe Wallet.
We have added so many simple and convenient options for you to help you reach your saving goals.
You can choose to save daily, weekly, monthly, semi-annually, annually and even more, as you would want.
Choose the most convenient method you would like and earn high interest on your savings.
We have also sorted out the best and secured investments for you.
Choose from anyone, and you can earn even higher interest on investment plans. 

            <Link to="/savings"> 
            <motion.button
         variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}
         className="card-btn">Learn more
          </motion.button>
          </Link>
        </div>
        <div className='card-img'>
            <img src={card6} alt="Savings and Investment" />
        </div>
    </div>




    <div className='card-container seven'>
        <div className='card-text'>
            <h2>Education and Sports</h2>
Do you know you can pay some education levy using Tribe Wallet?
You can pay school fees, WAEC, NECO, GCE, NABTEB, and many more.
Are you also a sports lover?
With Tribe Wallet, you can easily fund your sports accounts on the go, very fast, reliable and convenient. 

            <Link to="/education-sports"> 
            <motion.button
         variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}
         className="card-btn">Learn more
          </motion.button>
          </Link>
        </div>
        <div className='card-img'>
            <img src={card8} alt="Education and Sports" />
        </div>
    </div>


    <div className='card-container eight'>
        <div className='card-text'>
            <h2>Cards</h2>
Tribe Wallet offers physical and virtual card.
You can use our physical card to withdraw money across all ATM and POS in Nigeria, and make payments too.
This works as your regular bank card.
With Tribe Naira and dollar virtual card, you can make payment and purchase across all online platforms at ease.
We offer master, visa and verve cards.
You can do more with Tribe Physical and Virtual Cards.

            <Link to="/physical-vitual"> 
            <motion.button
         variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}
         className="card-btn">Learn more
          </motion.button>
         </Link>
        </div>
        <div className='card-img'>
            <img src={card5} alt="Physical and Vitual Cards" />
        </div>
    </div>


    <div className='card-container nine'>
        <div className='card-text'>
            <h2>Spin and Win</h2>
Just as the saying goes, “All work and no play, makes Jack a dull boy.”
There's no dull moment for our users.
We have got a strong reward system for our loyal users.
In this reward system, we reward our loyal and committed users with many gifts.
This could be cash prices, tickets for your favorite TV shows, Live Football matches and even electronics and gadgets.
Who knows, you could be our next iPhone 14 Pro max winner.
Many YafunYafun awaits you when you join Tribe Wallet.

            <Link to="/spin"> 
            <motion.button
         variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}
         className="card-btn">Learn more
          </motion.button>
          </Link>
        </div>
        <div className='card-img'>
            <img src={card9} alt="Spin and Win" />
        </div>
    </div>


    </div>
  )
}

export default Card