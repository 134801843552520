import React from 'react'
import phoneimg from '../img/phone.png'
import {motion} from 'framer-motion'
import play from '../img/playstore.svg'
import apple from '../img/apple.svg'



function Centre(props) {

  return (
    <div className='Centre' onClick={props.toggleall}>
        <h1 className='title'>Having you on Tribe is our favourite part of the day!</h1>
        <div className='centre-container'>
            <div className='phone-img'>
                <img src={phoneimg} alt="img"/>
            </div>
            <div className='centre-content'>
                <h1>Download Our <br/> Mobile App</h1>
                <motion.div 
      
      className="centre-btn">
      <motion.button    variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}>
        <a href="https://www.tribewallet.co">
        <img src={play} alt="Playstore"></img>
        </a>
            
          </motion.button>
          <motion.button    variants={props.btnGroup}
         whileHover={{scale:1.05}}
         whileTap={{scale:0.95}}>
        <a href="https://www.tribewallet.co">
        <img src={apple} alt="Applestore"></img>
        </a>
          </motion.button>
     </motion.div>
            </div>
        </div>
    </div>
  )
}

export default Centre